.dropzone {
  background-color: lightgrey;
  height: 400px;
  border: 4px dashed cadetblue;
  .dz-message{
    width:50%;
    text-align: center;
    display: block;
    margin: 190px auto;
  }
}
.dz-preview{
  display:none;
}
