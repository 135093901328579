table{
  background: #fff;
}

.table-success{
  background: $state-success-bg !important;
}

.table-danger{
  background: $state-danger-bg !important;
}

.table-warn{
  background: $state-warning-bg !important;
}

.table tbody > tr > td.v-aligned {
  vertical-align: middle;
}