@import "../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../../../../node_modules/bootstrap-select/sass/bootstrap-select.scss";
@import "../../../../node_modules/chosen-js/chosen";

$light-blue: #83c7dd;
$dark-blue: #337ab7;
$purple: #637ab1;
$grey: #f8f8f8;
$very-light-grey: #fdfdfd;
$light-grey: #f2f2f2;
$dark-grey: #777;
$black: #333;
$dark-grey: #1d1f28;
$grey: #46475c;
$light-grey: #868686;
$lighter-grey: #aaa;
$very-light-orange: #ea861a;
$dark-orange: #e1651a;
$orange: #e16e10;
$blue: #3dacad;
$green: #66a866;
$red: #632935;
$yellow: #d3b41b;
$very-light-green: rgba(131, 136, 120, 0.3);
$very-light-red: #a88a91;
$wb-green: rgb(0, 179, 186);
$wb-pink: rgb(232,59, 132);
$wb-purple: rgb(84, 37, 130);
$wb-light-purple: rgb(189, 184, 220);
$wb-red: rgb(255, 0, 0);
$ib-light-green: rgb(154, 186, 177);

$call-inbound: rgb(255,63,226);
$call-internal: rgb(86,151,255);
$call-outbound: rgb(121,63,255);



$border: 1px solid #e7e7e7;

@import "components/spacers";
@import "components/spinner";
@import "components/header";
@import "components/table";
@import "components/bootstrap-overrides";
@import "components/backgrounds";
@import "components/summary";
@import "components/text";
@import "components/xmas";
@import "components/development";
@import "components/forms";
@import "components/panels";
@import "weekly-broadband/statistics";
@import "weekly-broadband/bills";
@import "weekly-broadband/tabs";
@import "weekly-broadband/popovers";
@import "mycalls/statistics";
@import "../lib/fifths";
@import "../lib/dropzone";
@import "../lib/dragtable";
@import "../lib/bootstrap-table";
@import "../lib/jquery-ui";
@import "../lib/blink";
@import "components/images";
@import "../../../../public/fonts/icons/style.scss";

body{
  background: #f2f2f2 !important;
}
[v-cloak] {
  display:none;
}

