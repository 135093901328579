.spinner{
  h1{
    margin: 0;
  }
}

@keyframes rotating {
  0%   {
    transform: rotate(0deg);
    color: $light-blue;
  }
  50% {
    color:$purple;
    transform: rotate(180deg);
  }
  100% {
    color:$light-blue;
    transform: rotate(360deg);
  }
}
.rotate {
  animation: rotating 1.2s infinite;
}