#weekly_broadband_statistics_container {
  .panel-default {
    .panel-heading {
      background-color: $wb-purple;
      color: #fff;
      height: 6rem;
      @media (min-width: $screen-md-max) {
        height: 4rem;
      }
    }
    .record{
      text-align: center;
      color:#737373;
    }
    .options {
      margin-bottom: 0;
    }
    .icon-table {
      color: $wb-pink;
    }
  }
}