.navbar{
  margin-bottom: 0 !important;
}

.modal-dialog-large{
  width: 100% !important;
  @media (min-width: $screen-md-min) {
    width: 1400px !important;
    max-width: 100% !important;
  }
}

.modal-dialog-md{
  width: 100% !important;
  @media (min-width: $screen-md-min) {
    width: 1100px !important;
    max-width: 100% !important;
  }
}

.modal-dialog-xl{
  width: 100% !important;
  @media (min-width: $screen-md-min) {
    width: 1800px !important;
    max-width: 100% !important;
  }
}

.label-pink{
   background-color: #ff51e8;
 }
.label-grey{
  background-color: #555;
}
.label-black{
  background-color: #000;
}
.label-yellow{
  background-color: #f4ff26;
  color:#777 !important;
}
.label-warning{
  font-size: 75% !important;
  padding: 0;
}

.btn-yellow{
  background-color: #f4ff26;
  color:#777;
}

.btn-deactive{
  background: transparent !important;
  border-color: #999 !important;
  color: #000 !important;
}

.btn-full{
  width:100%;
  text-align: center;
}

.btn-toolbar-vertical{
  .btn{
    margin-bottom: 0.2rem;
  }
}

.badge {
  padding: 1px 9px 2px;
  font-size: 12.025px;
  font-weight: bold;
  white-space: nowrap;
  color: #ffffff;
  background-color: #999999;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}
.badge:hover {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
.badge-error {
  background-color: #b94a48 !important;
}
.badge-error:hover {
  background-color: #953b39;
}
.badge-warning {
  background-color: #f89406 !important;
}
.badge-warning:hover {
  background-color: #c67605;
}
.badge-success {
  background-color: #468847 !important;
}
.badge-success:hover {
  background-color: #356635;
}
.badge-info {
  background-color: #3a87ad !important;
}
.badge-info:hover {
  background-color: #2d6987;
}
.badge-inverse {
  background-color: #333333 !important;
}
.badge-inverse:hover {
  background-color: #1a1a1a;
}

.badge-white {
  background-color: #ffffff !important;
  color: #000000!important;
}

.ideal{
  .panel-default{
    .panel-heading{
      background-color: $ib-light-green !important;
    }
  }
}

.weekly{
  .panel-default{
    .panel-heading{
      background-color: $wb-light-purple !important;
    }
  }
}

table.table{
  tbody{
    tr{
      td.active{
        background-color: #ccc7c7;
      }
    }
  }
}

