.text-red {
  color: red;
}

.text-blue {
  color: blue;
}

.text-green {
  color: green;
}

.text-yellow {
  color: #c19222;
}

.text-light-grey {
  color: $light-grey;
}

.text-white {
  color: #fff;
}

.menu-subheading{
  color: $light-blue;
  font-size: 1.2rem;
  text-align: left;
  font-style: italic;
  padding-left: 20px;
}

.label-inbound{
  background-color: $call-inbound;
}

.label-internal{
  background-color: $call-internal;
}

.label-outbound{
  background-color: $call-outbound;
}