.bk-blue{
   background: $blue;
 }
.bk-red{
  background: #ad3d3d;
}
.bk-green{
  background: $green;
}
.bk-orange{
  background: $orange;
}
.bk-yellow{
  background: $yellow;
}
