.task-step{
  border: 1px solid $light-grey;
  padding:1rem;
  border-radius: $border-radius-base;
  margin-bottom: 1rem;
}
.task-step:hover{
  cursor: hand;
}

.project-main-container{
  border: 1px solid $light-grey;
  border-radius: $border-radius-base;
  margin-bottom: 1rem;
  background: #fff;
  .heading{
    background: $dark-blue;
    padding:1rem;
    color:#fff;
    h3{
      color:#fff;
      margin: 0;
    }
    p{
      margin: 0;
    }
  }
  .project-container{
    padding:1rem;
    .project{
      border: 1px solid $light-grey;
      padding:0;
      border-radius: $border-radius-base;
      margin-bottom: 1rem;
      background: $very-light-grey;
    }
    .project::hover{
      cursor: grab;
    }
    .project.current{
      background-color: $very-light-green;
    }
    .summary{
      margin: 1rem;
    }
    .table{
      font-size: 1.1rem;
      text-align: center;
      margin: 0.5rem 1rem 1rem 1rem;
      td, th{
        border: none;
        text-align: center;
        padding: 0.3rem;
      }
    }
    .progress-container{
      width:100%;
      background: $light-grey;
      height: 0.5rem;
      .progress-bar{
        width:0;
        height: 0.5rem;
        background: $red;
      }
      .progress-bar.green{
        background: $green;
      }
      .progress-bar.yellow{
        background: $yellow;
      }
    }
  }
}

