.space-1{
  margin-top: 1rem !important;
}
.space-2{
  margin-top: 2rem !important;
}

.space-3{
  margin-top: 3rem !important;
}

.space-4{
  margin-top: 4rem !important;
}