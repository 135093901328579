.panel-summary {
  float:left;
  width:100%;
  margin-bottom: 1.5rem;
  background: #f5f5f5;
  border: 1px #ddd solid;
  border-radius: 4px;
  p.title {
    text-align: right;
    margin-bottom: 0;
    font-size: 1.6rem; //icon
  }
  p.total{
    text-align: right;
    font-size: 6rem;
    line-height: 6rem;
    margin-bottom: 0.3rem;
  }
  .icon-container {
    float:left;
    width:35%;
    text-align: center;
    font-size: 7rem;
    color:#fff;
    padding: 3rem;
  }
  .text-container{
    float:left;
    width:65%;
    padding: 2.6rem 1.5rem 1.5rem 1.5rem;
  }
}