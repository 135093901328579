.top-header{
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#d7e8f2+0,337ab7+100 */
  background: #d7e8f2; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #d7e8f2 0%, #337ab7 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #d7e8f2 0%,#337ab7 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #d7e8f2 0%,#337ab7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d7e8f2', endColorstr='#337ab7',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  color:#fff;
  width:100%;
  overflow-x: hidden;
  a, h2, small{
    color:#fff;
  }
  h2{
    margin-top: 1rem;
  }
}
.top-header.ideal{
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#d7e8f2+0,337ab7+100 */
  background: #278677; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #5ba87b 0%, #278677 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #5ba87b 0%,#278677 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #8DF2BE 0%,#5ba87b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d7e8f2', endColorstr='#337ab7',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.top-header.weekly{
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#d7e8f2+0,337ab7+100 */
  background: #542583; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #8b3bd0 0%, #542583 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #8b3bd0 0%,#542583 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #8b3bd0 0%,#542583 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d7e8f2', endColorstr='#337ab7',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}



.logo{
  margin-left: 2rem;
  img{
    width:100px;
    @media (min-width: $screen-md-min) {
      width:300px;
    }
  }
}
.logo:hover{
  a{
    text-decoration: none;
  }
}