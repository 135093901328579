@font-face {
  font-family: 'icomoon';
  src:  url('../../../fonts/icons/fonts/icomoon.eot');
  src:  url('../../../fonts/icons/fonts/icomoon.eot') format('embedded-opentype'),
    url('../../../fonts/icons/fonts/icomoon.ttf') format('truetype'),
    url('../../../fonts/icons/fonts/icomoon.woff') format('woff'),
    url('../../../fonts/icons/fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'built-titling-regular';
  src:  url('/fonts/BUILT-TITLING-RG.woff') format('woff'), /* Pretty Modern Browsers */
  url('/fonts/BUILT TITLING RG.ttf');
}
@font-face {
  font-family: 'built-titling-semi-bold';
  src:  url('/fonts/BUILT-TITLING-SB.woff') format('woff'), /* Pretty Modern Browsers */
  url('/fonts/BUILT TITLING SB.ttf');
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-handshake-o:before {
  content: "\e949";
}
.icon-Burger-Menu-Icon-01:before {
  content: "\e946";
}
.icon-line-and-calls:before {
  content: "\e944";
}
.icon-multi-site:before {
  content: "\e945";
}
.icon-Case-Studies-Icon-01:before {
  content: "\e93c";
}
.icon-Datasheets-and-Guides-Icon-01:before {
  content: "\e93e";
}
.icon-FAQs-Icon-01:before {
  content: "\e93f";
}
.icon-Jargon-Buster-Icon-01:before {
  content: "\e940";
}
.icon-Latest-Blogs-Icon-01:before {
  content: "\e941";
}
.icon-Useful-Information-Icon-01:before {
  content: "\e943";
}
.icon-Business-Broadband-Icon_Homepage:before {
  content: "\e936";
}
.icon-Business-Mobile-Icon_Homepage:before {
  content: "\e937";
}
.icon-Email-Icon:before {
  content: "\e938";
}
.icon-Hosted-Solutions-Icon_Homepage:before {
  content: "\e939";
}
.icon-Phone-Icon:before {
  content: "\e93a";
}
.icon-Phone-Systems-Icon_Homepage:before {
  content: "\e93b";
}
.icon-controller-fast-backward:before {
  content: "\e92f";
}
.icon-controller-fast-forward:before {
  content: "\e930";
}
.icon-controller-paus:before {
  content: "\e931";
}
.icon-controller-play:before {
  content: "\e932";
}
.icon-old-mobile:before {
  content: "\e92e";
}
.icon-quotes-left2:before {
  content: "\e977";
}
.icon-quotes-right:before {
  content: "\e978";
}
.icon-connection2:before {
  content: "\e933";
}
.icon-file-text2:before {
  content: "\e934";
}
.icon-stack:before {
  content: "\e935";
}
.icon-coin-pound:before {
  content: "\e93d";
}
.icon-phone2:before {
  content: "\e942";
}
.icon-alarm:before {
  content: "\e950";
}
.icon-stopwatch2:before {
  content: "\e952";
}
.icon-printer:before {
  content: "\e954";
}
.icon-keyboard:before {
  content: "\e955";
}
.icon-mobile:before {
  content: "\e958";
}
.icon-tv:before {
  content: "\e95b";
}
.icon-wrench2:before {
  content: "\e991";
}
.icon-cog2:before {
  content: "\e994";
}
.icon-tree:before {
  content: "\e9bc";
}
.icon-heart-broken:before {
  content: "\e9db";
}
.icon-happy:before {
  content: "\e9df";
}
.icon-smile:before {
  content: "\e9e1";
}
.icon-sad:before {
  content: "\e9e5";
}
.icon-wink:before {
  content: "\e9e7";
}
.icon-cool:before {
  content: "\e9eb";
}
.icon-angry:before {
  content: "\e9ed";
}
.icon-shocked:before {
  content: "\e9f1";
}
.icon-baffled:before {
  content: "\e9f3";
}
.icon-confused:before {
  content: "\e9f5";
}
.icon-hipster:before {
  content: "\e9f9";
}
.icon-frustrated:before {
  content: "\e9ff";
}
.icon-crying:before {
  content: "\ea01";
}
.icon-enter:before {
  content: "\ea13";
}
.icon-exit:before {
  content: "\ea14";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-user:before {
  content: "\e971";
}
.icon-users:before {
  content: "\e972";
}
.icon-pie-chart:before {
  content: "\e99a";
}
.icon-stats-dots2:before {
  content: "\e99b";
}
.icon-stats-bars:before {
  content: "\e99c";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-home:before {
  content: "\e906";
}
.icon-home2:before {
  content: "\e907";
}
.icon-home3:before {
  content: "\e908";
}
.icon-office:before {
  content: "\e909";
}
.icon-pencil:before {
  content: "\e90a";
}
.icon-connection:before {
  content: "\e90b";
}
.icon-podcast:before {
  content: "\e90c";
}
.icon-file-text:before {
  content: "\e90d";
}
.icon-profile:before {
  content: "\e925";
}
.icon-cart:before {
  content: "\e90e";
}
.icon-credit-card:before {
  content: "\e90f";
}
.icon-phone:before {
  content: "\e900";
}
.icon-envelop:before {
  content: "\e901";
}
.icon-location:before {
  content: "\e902";
}
.icon-clock:before {
  content: "\e910";
}
.icon-stopwatch:before {
  content: "\e911";
}
.icon-calendar:before {
  content: "\e912";
}
.icon-download:before {
  content: "\e926";
}
.icon-upload:before {
  content: "\e927";
}
.icon-bubble:before {
  content: "\e903";
}
.icon-bubbles4:before {
  content: "\e913";
}
.icon-quotes-left:before {
  content: "\e904";
}
.icon-spinner9:before {
  content: "\e914";
}
.icon-search:before {
  content: "\e915";
}
.icon-wrench:before {
  content: "\e916";
}
.icon-equalizer:before {
  content: "\e917";
}
.icon-cog:before {
  content: "\e918";
}
.icon-cogs:before {
  content: "\e928";
}
.icon-bug:before {
  content: "\e929";
}
.icon-stats-dots:before {
  content: "\e92a";
}
.icon-stats-bars2:before {
  content: "\e919";
}
.icon-rocket:before {
  content: "\e91a";
}
.icon-meter:before {
  content: "\e91b";
}
.icon-bin:before {
  content: "\e91c";
}
.icon-target:before {
  content: "\e91d";
}
.icon-switch:before {
  content: "\e91e";
}
.icon-menu:before {
  content: "\e905";
}
.icon-warning:before {
  content: "\e92b";
}
.icon-info:before {
  content: "\e92c";
}
.icon-blocked:before {
  content: "\e92d";
}
.icon-checkmark:before {
  content: "\e91f";
}
.icon-checkbox-checked:before {
  content: "\e920";
}
.icon-checkbox-unchecked:before {
  content: "\e921";
}
.icon-radio-checked:before {
  content: "\e922";
}
.icon-radio-checked2:before {
  content: "\e923";
}
.icon-rss:before {
  content: "\e924";
}
.icon-facebook2:before {
  content: "\ea91";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-skype:before {
  content: "\eac5";
}
.icon-linkedin:before {
  content: "\eac9";
}
.icon-signal:before {
  content: "\f012";
}
.icon-bar-chart:before {
  content: "\f080";
}
.icon-bar-chart-o:before {
  content: "\f080";
}
.icon-tasks:before {
  content: "\f0ae";
}
.icon-table:before {
  content: "\f0ce";
}
.icon-file-text-o:before {
  content: "\f0f6";
}
.icon-fire:before {
  content: "\e9a9";
}
.icon-shield:before {
  content: "\e9b4";
}
.icon-file-pdf:before {
  content: "\eadf";
}
.icon-arrow-right:before {
  content: "\ea34";
}
.icon-arrow-left:before {
  content: "\ea38";
}
.icon-arrow-up:before {
  content: "\ea32";
}
.icon-arrow-down:before {
  content: "\ea36";
}
.icon-signal:before {
  content: "\f012";
}
.icon-bar-chart:before {
  content: "\f080";
}
.icon-bar-chart-o:before {
  content: "\f080";
}
.icon-tasks:before {
  content: "\f0ae";
}
.icon-table:before {
  content: "\f0ce";
}
.icon-file-text-o:before {
  content: "\f0f6";
}
.icon-question:before {
  content: "\ea09";
}
.icon-gallery:before {
  content: "\e935";
}
.icon-moon:before {
  content: "\e948";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-drive:before {
  content: "\e963";
}
.icon-power:before {
  content: "\e9b5";
}
.icon-power-cord:before {
  content: "\e9b7";
}
.icon-bold:before {
  content: "\ea62";
}
.icon-share2:before {
  content: "\ea82";
}
.icon-heart:before {
  content: "\e9da";
}
